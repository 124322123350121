import React, { useEffect } from 'react';

import { Link } from 'gatsby';

const SubPageNav = (props) =>{

    let chkPage = props.static;

    useEffect(() => {

        let target = document.getElementsByClassName('js-sub-nav')[0]

        if(chkPage === 'message'){
            target.scrollLeft += 0
        }else if(chkPage === 'vision'){
            target.scrollLeft += 50
        }else if(chkPage === 'outline'){
            target.scrollLeft += 100
        }else if(chkPage === 'history'){
            target.scrollLeft += 200
        }else if(chkPage === 'group'){
            target.scrollLeft += 250
        }

    },[chkPage]);



    return(
        <>

            <div className="c-sub-header-nav-wrap">
                <div className="c-sub-header-nav js-sub-nav">
                    <Link to="/message" title="メッセージ" className={`c-sub-header-nav--btn ${chkPage === 'message' ? 'is-active' : '' }`}>
                        メッセージ
                    </Link>

                    <Link to="/vision" title="ビジョン" className={`c-sub-header-nav--btn ${chkPage === 'vision' ? 'is-active' : '' }`}>
                        ビジョン
                    </Link>

                    <Link to="/outline" title="会社概要" className={`c-sub-header-nav--btn ${chkPage === 'outline' ? 'is-active' : '' }`}>
                        会社概要
                    </Link>

                    <Link to="/history" title="沿革" className={`c-sub-header-nav--btn ${chkPage === 'history' ? 'is-active' : '' }`}>
                        沿革
                    </Link>

                    <Link to="/group" title="グループ企業" className={`c-sub-header-nav--btn ${chkPage === 'group' ? 'is-active' : '' }`}>
                        グループ企業
                    </Link>
                </div>
            </div>

        </>
    )
}

export default SubPageNav;


