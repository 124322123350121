import React from 'react';

import Meta from '../components/meta';

import ExtLink from '../components/extLink';

import { StaticImage } from "gatsby-plugin-image";

import Contents from '../components/contents';

import SubPageNav from '../components/subPageNav';

const Group = () =>{
    return(
        <>

            <Meta
                title="グループ企業"
                description="アクロスソリューションズのグループ企業についてご案内します。"
            />

            <Contents>

                <div className="c-sub-header">
                    <SubPageNav static="group" />

                    <em className="c-sub-header--eng u-josef">
                    GROUP
                    </em>

                    <h1 className="c-sub-header--title">
                    グループ企業
                    </h1>
                </div>



                <div className="p-group">
                    <div className="p-group-list">

                        <div className="p-group-list-item">
                            <div className="p-group-box">
                                <div className="p-group-box-image">
                                    <ExtLink link="https://www.sts-inc.co.jp/" title="株式会社システムサポート" classTag="p-group-box-image--btn" target="true">
                                        <StaticImage
                                        src="../images/sts_logos.png"
                                        alt="株式会社システムサポート"
                                        placeholder="blurred"
                                        quality="100"
                                        />
                                    </ExtLink>
                                </div>

                                <div className="p-group-box-cnt">
                                    <p className="p-group-box--title">
                                    株式会社システムサポート
                                    </p>

                                    <p className="p-group-box--desc">
                                    システムサポートでは、ICTを活用したシステムのコンサルティングから、企画・ 開発・構築、運用・保守、さらにはIT技術者の教育まで、システムライフサイクル を支援する専門サービスを総合的にご用意しております。医療・流通・製造などの 幅広い業界で私たちがこれまでに培ってきた豊富な経験とノウハウ、そして高い技 術力に裏付けられた質の高いサービスにより、お客様のICT環境をあらゆる側面か ら強力にサポートします。
                                    </p>

                                    <div className="c-link u-align-right">
                                        <ExtLink link="https://www.sts-inc.co.jp/" title="株式会社システムサポート" classTag="c-link--btn is-external" target="true">
                                        ウェブサイト<span className="c-link--cursor"></span>
                                        </ExtLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-group-list-item">
                            <div className="p-group-box">
                                <div className="p-group-box-image">
                                    <ExtLink link="https://www.enetsolutions.co.jp/" title="株式会社イーネットソリューションズ" classTag="p-group-box-image--btn" target="true">
                                        <StaticImage
                                        src="../images/enet_logos.png"
                                        alt="株式会社イーネットソリューションズ"
                                        placeholder="blurred"
                                        quality="100"
                                        />
                                    </ExtLink>
                                </div>

                                <div className="p-group-box-cnt">
                                    <p className="p-group-box--title">
                                    株式会社イーネットソリューションズ 
                                    </p>

                                    <p className="p-group-box--desc">
                                    株式会社イーネットソリューションズでは、「データセンターのコンシェルジュ」を サービスコンセプトとして、万全のファシリティを持つ国内のデータセンターを核に、 ホスティングサービス、ハウジングサービス、ASP・SaaSサービスといったネットワ ーク・ソリューションサービスを中心に幅広いサービスをラインナップしております。 常にお客さまのそばに寄り添い、お客さまの課題解決のために、最良かつ最適なサー ビスをご提供いたします。
                                    </p>

                                    <div className="c-link u-align-right">
                                        <ExtLink link="https://www.enetsolutions.co.jp/" title="株式会社イーネットソリューションズ" classTag="c-link--btn is-external" target="true">
                                        ウェブサイト<span className="c-link--cursor"></span>
                                        </ExtLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-group-list-item">
                            <div className="p-group-box">
                                <div className="p-group-box-image">
                                    <ExtLink href="https://www.sts-medic.jp/" title="株式会社STSメディック" classTag="p-group-box-image--btn" target="true">
                                        <StaticImage
                                        src="../images/medic_logos.png"
                                        alt="株式会社STSメディック"
                                        placeholder="blurred"
                                        quality="100"
                                        />
                                    </ExtLink>
                                </div>

                                <div className="p-group-box-cnt">
                                    <p className="p-group-box--title">
                                    株式会社STSメディック
                                    </p>

                                    <p className="p-group-box--desc">
                                    株式会社STSメディックでは、医療情報関連システムのソフトウェアの開発・販売 サービスを主軸に、大規模病院の部門システムからクリニック様までニーズに合った ラインナップをご提供しております。当社が開発・販売しております医用画像管理 ファイリングシステム「T-File System」は、高画質な画像保存・閲覧、所見レポート 作成、データの有効活用など、医療現場における診療業務を強力にサポートいたします。
                                    </p>

                                    <div className="c-link u-align-right">
                                        <ExtLink link="https://www.sts-medic.jp/" title="株式会社STSメディック" classTag="c-link--btn is-external" target="true">
                                        ウェブサイト<span className="c-link--cursor"></span>
                                        </ExtLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-group-list-item">
                            <div className="p-group-box">
                                <div className="p-group-box-image">
                                    <ExtLink link="https://www.t4c.co.jp/" title="株式会社T4C" classTag="p-group-box-image--btn" target="true">
                                        <StaticImage
                                        src="../images/t4c_logos.png"
                                        alt="株式会社T4c"
                                        placeholder="blurred"
                                        quality="100"
                                        />
                                    </ExtLink>
                                </div>

                                <div className="p-group-box-cnt">
                                    <p className="p-group-box--title">
                                    株式会社T4C
                                    </p>

                                    <p className="p-group-box--desc">
                                    株式会社T4Cでは、得意とする会計分野を中心に、販売・在庫管理・購買・人事といっ た幅広い領域において際立った専門性を兼ね備えたエキスパートがお客様のシステムの 難問を解決いたします。SAPを利用した「連結会計ソリューション」「小売業向けソリ ューション」等の導入においては、SAP社とお客様を結ぶサービスパートナーとして、 そしてERP実現を目指す企業の最良のパートナーとして、お客様を全力で支えていきます。
                                    </p>

                                    <div className="c-link u-align-right">
                                        <ExtLink link="https://www.t4c.co.jp/" title="株式会社T4C" classTag="c-link--btn is-external" target="true">
                                        ウェブサイト<span className="c-link--cursor"></span>
                                        </ExtLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-group-list-item">
                            <div className="p-group-box">
                                <div className="p-group-box-image">
                                    <ExtLink link="https://sts-d.com/" title="株式会社STSデジタル" classTag="p-group-box-image--btn" target="true">
                                        <StaticImage
                                        src="../images/stsd_logos.png"
                                        alt="株式会社STSデジタル"
                                        placeholder="blurred"
                                        quality="100"
                                        />
                                    </ExtLink>
                                </div>

                                <div className="p-group-box-cnt">
                                    <p className="p-group-box--title">
                                    株式会社STSデジタル
                                    </p>

                                    <p className="p-group-box--desc">
                                    株式会社STSデジタルでは、お客様の商品・サービスの売り上げ向上、そしてブランドの発展を支援するマーケティング特化型サービスをご提供しております。PR・販売促進・集客支援・メディア戦略などの専門スタッフで構成され、市場環境とニーズにあわせたプランニングと実行をサポート。あらゆる企業のマーケティングパートナーとして、世の中に「価値」を広めるための総合的なデジタルソリューションを生み出し続ける会社です。
                                    </p>

                                    <div className="c-link u-align-right">
                                        <ExtLink link="https://sts-d.com/" title="株式会社STSデジタル" classTag="c-link--btn is-external" target="true">
                                        ウェブサイト<span className="c-link--cursor"></span>
                                        </ExtLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-group-list-item">
                            <div className="p-group-box">
                                <div className="p-group-box-image">
                                    <ExtLink link="https://www.cpnet.co.jp/" title="株式会社コミュニケーション・プランニング" classTag="p-group-box-image--btn" target="true">
                                        <StaticImage
                                        src="../images/cpn_logos.png"
                                        alt="株式会社コミュニケーション・プランニング"
                                        placeholder="blurred"
                                        quality="100"
                                        />
                                    </ExtLink>
                                </div>

                                <div className="p-group-box-cnt">
                                    <p className="p-group-box--title">
                                    株式会社コミュニケーション・プランニング
                                    </p>

                                    <p className="p-group-box--desc">
                                    株式会社コミュニケーション・プランニングでは、仮想空間技術を活用したXRソリューション事業、地図・位置情報ソリューション事業、人事領域を中心としたERP導入支援を行う人事システムコンサルティング事業を手掛けております。これまでの実績や蓄積された技術力に加え、新たなテクノロジーを取り入れることで、お客様や社会にイノベーションを起こす製品やサービスをご提供いたします。
                                    </p>

                                    <div className="c-link u-align-right">
                                        <ExtLink link="https://www.cpnet.co.jp/" title="株式会社コミュニケーション・プランニング" classTag="c-link--btn is-external" target="true">
                                        ウェブサイト<span className="c-link--cursor"></span>
                                        </ExtLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-group-list-item">
                            <div className="p-group-box">
                                <div className="p-group-box-image">
                                    <ExtLink link="https://www.sts-innovation.com/" title="STS INNOVATION,INC." classTag="p-group-box-image--btn" target="true">
                                        <StaticImage
                                        src="../images/innovation_logos.png"
                                        alt="STS INNOVATION,INC."
                                        placeholder="blurred"
                                        quality="100"
                                        />
                                    </ExtLink>
                                </div>

                                <div className="p-group-box-cnt">
                                    <p className="p-group-box--title">
                                    STS INNOVATION,INC.
                                    </p>

                                    <p className="p-group-box--desc">
                                    STS Innovation, Inc.では、国内のお客様へ、米国の最新テクノロジー情報やクラウド分野 を中心とする先進的なサービスをご提供します。また、日本からシリコンバレーを目指す 企業や公共・教育機関の現地調査、および、セットアップのご支援、在米日系企業を中心 に、ITコンサルティング、システムインテグレーションおよび、 アウトソーシングサービ スをご提供します。
                                    </p>

                                    <div className="c-link u-align-right">
                                        <ExtLink link="https://www.sts-innovation.com/" title="STS INNOVATION,INC." classTag="c-link--btn is-external" target="true">
                                        ウェブサイト<span className="c-link--cursor"></span>
                                        </ExtLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-group-list-item">
                            <div className="p-group-box">
                                <div className="p-group-box-cnt">
                                    <p className="p-group-box--title">
                                    STS Innovation Canada Inc.
                                    </p>

                                    <p className="p-group-box--desc">
                                    主に在北米の日系企業に対し、会計・給与計算・人事といった管理業務のビジネス・プロセス・アウトソーシングサービスをご提供いたします。
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </Contents>

        </>
    )
}

export default Group;


